










import { Component, Vue } from 'vue-property-decorator';
import RequestQuoteForm from '@/modules/marketing/forms/RequestQuoteForm.vue';
@Component({
  name: 'quote',
  layout: 'default',
  metaInfo: {
    title: 'Request a quote of Check-in buddy',
  },
  components: {
    RequestQuoteForm,
  },
})
export default class Demo extends Vue {}

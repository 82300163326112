<template>
  <div class="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
    <div class="max-w-4xl mx-auto">
      <div class="text-center mb-4">
        <h1 class="mb-4 md:text-4xl text-gray-900 font-bold font-heading">Request a Quote</h1>
        <p class="mt-4 text-lg leading-6 text-gray-500"
          >Do you require more than 1,500 check-ins per month? <br />
          Contact us with further details about your establishment and we will get back to you with a quote.</p
        >
      </div>

      <div id="hubspotForm" v-once></div>
    </div>
  </div>
</template>
<script>
import { setTimeout } from 'timers';
export default {
  mounted() {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);
    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '9184409',
          formId: 'abaea549-bd60-4c9f-9da1-fe8985ca66e2',
          target: '#hubspotForm',
        });
      }
    });
  },
};
</script>
